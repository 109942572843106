
import Link from '@/components/link/index.vue'
import { defineComponent } from 'vue'
import { translate } from '@/languages/i18n'

export default defineComponent({
  components: { Link },
  name: 'CardFooter',
  props: {
    subInfo: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      links: [
        {
          title: translate('forgotPassword'),
          path: '/alumni/forgot_password',
        },
      ],
    }
  },
})
